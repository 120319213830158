body {
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-bottom: 50px;
}

p {
  font-size: 1em;
}
.logotype {
  width: 200px;
}
.react-calendar.calendar {
  width: 100%;
  max-width: 500px;
  line-height: 2.5em;

  .react-calendar__navigation button {
    font-size: 2em;
  }

  .react-calendar__tile--now {
    color: #fff;
    background: #fd9b37;
  }

  .react-calendar__tile--active {
    background: #007bff;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #208afc;
  }

  .marked-date {
    background: #3494fb;
  }
}

.btn.btn-matchplay {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
  padding: 0.5em 1.5em;
  font-size: 1em;
}
.btn-matchplay:hover {
  color: #fff;
  background-color: #208afc;
  border-color: #208afc;
}

.text-matchplay {
  --bs-text-opacity: 1;
  color: rgba(56, 177, 84, var(--bs-text-opacity)) !important;
}

.selected-dates {
  width: 100%;
  max-width: 500px;
  padding-top: 10px;
  text-align: left;
}
.selected-dates .icon {
  cursor: pointer;
}
.selected-dates .deleted {
  text-decoration: line-through;
  text-decoration-color: crimson;
}

.admin .team-confirmed {
  color: #28a745;
}
